.headline{
    color: var(--text-color);
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: medium;
    font-family: 'Cousine', monospace;
    margin: 16px;
    padding: 0;
}

.headbar-opened{
    width: 100%;
    display: flex;
    border-radius: .5vh .5vh 0vh 0vh;
    border-bottom: 1px solid var(--bg1);
    background-color: var(--bg2);
}

.headbar-closed{
    width: 100%;
    display: flex;
    border-radius: .5vh;
    background-color: var(--bg2);
}

.analysis-container{
    font-size: small;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: .5vh;
    padding: 16px;
    background-color: var(--bg1);
}

.analysis-container-admin{
    display: flex;
    flex-direction: column;
    border-radius: .5vh;
    width: 100%;
    padding: 1vh;
    margin: 1vh;
}

.analysis-container-input-admin{
    background-color: rgb(53, 70, 65);
    border-radius: 10000px;
    color: var(--text-color);
    outline: none;
    word-wrap: break-word;
    border: 0;
    padding: 8px 12px;
    margin-bottom: 12px;
}

.analysis-container-submit-admin{
    background: rgb(53, 70, 65);
    border-radius: 10000px;
    color: var(--text-color);
    outline: none;
    width: 150px;
    border: 0;
    padding: 8px 12px;
    margin-bottom: 12px;
    margin-top: 12px;
}

.analysis-container-submit-admin:focus{
    box-shadow: 0 0 0 2px var(--bg1);
}

.analysis-container-input-label-admin{
    margin-bottom: 4px;
    width: 90%;
    outline: none;
    color: var(--text-color);
}

.drop-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 35px;
    padding: 16px;
}

.analysis-text{
    color: var(--text-color);
    padding-top: .1vh;
}

.chart-container{
    height: 20vh;
    width: 65%;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 16px 0px 16px;
}

.position{
    margin: 0px 8px 8px 8px;
    background-color: var(--bg2);
    border-radius: .5vh;
}

.teaser{
    display: inline;
    border: solid 1px palevioletred;
    border-radius: 100000px;
    padding: 0px 4px;
    color: palevioletred;
}

p{
    color: var(--text-color);
}