:root{
    --bar-height: 10vh;
}

#bar{
    width: 100%;
    background-color: var(--bg2);
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    transition: all 499ms ease;
}

.menu-hide{
    height: var(--bar-height);
}

.title{
    display: flex;
    align-items: center;
}

.titlestack{
    display: flex;
    flex-direction: column;
    align-items: left;
}

.titleimage{
    display: inline-block;
    vertical-align: middle;
    height: var(--bar-height);
}

.titletext{
    display: inline-block;
    color: var(--pop);
    padding: 0 0 0 1vh;
    margin: 0;
    vertical-align: middle;
}

.subtitle{
    display: inline-block;
    font-size: .5em;
    color: rgb(90, 86, 86);
    padding: 0 0 0 2vh;
    margin: 0;
    vertical-align: middle;
}

.links{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
}

.link:hover{
    border-top: solid 2px transparent;
    border-bottom: solid 2px var(--text-color);
}

.linkimg{
    font-size: medium;
    color: var(--pop);
}

.linktext{
    font-size: medium;
    white-space: nowrap;
    margin: 0;
    color: var(--text-color);
    display: inline-block;
    vertical-align: middle;
}

.expand-button{
    color: var(--pop);
    background: none;
    text-decoration: none;
    border: none;
    font-size: large;
    display: none;
}

.expand-button:hover{
    color: var(--bg1);
}

@media only screen and (max-width: 800px) {

    .titleimage{
        margin: 0;
    }

    .title{
        width: 100%;
    }

    #bar{
        flex-direction: column;
    }

    .links{
        flex-direction: column;
        width: 100%;
    }

    .link{
        border: none;
        background: none;
        cursor: pointer;
        text-decoration: none;
        width: 100%;
        padding: 8px;
        flex-direction: row;
    }

    .link:hover{
        border: 0;
        background-color: var(--bg1);
    }

    .linkimg{
        margin-right: 8px;
    }

    .expand-button{
        position: absolute;
        display: block;
        right: 16px;
    }

    #bar{
        justify-content: end;
    }
}

@media only screen and (min-width: 800px){
    #bar{
        height: var(--bar-height);
    }

    .link{
        height: calc(var(--bar-height) - 4px);
    }
}