#tooltip {
    position: relative;
    display: inline;
    border-bottom: 1px solid var(--pop);
}

#tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: var(--text);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

#tooltip:hover #tooltip-text {
    visibility: visible;
    z-index: 999999;
}

#tooltip-text::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }