html, body {
    background-color: var(--bg1);
    max-width: 100%;
    overflow-x: hidden;
    font-size: 16px;
}

main{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

a{
    text-decoration: none;
}

input{
    border: 0;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    color: inherit;
}

html{
    --bg1: rgb(21, 27, 32);
    --bg2: rgb(29, 38, 43);
    --pop: rgb(82,196,141);
    --text-color: rgb(82,196,141);
    --title-font: "Segoe UI";
    --text-font: "Cousine", monospace;
    font-family: "Cousine", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.ts{
    font-size: .7rem;
    font-family: var(--text-font);
    color: var(--text-color);
}

.tm{
    font-size: 1rem;
    font-family: var(--text-font);
    color: var(--text-color);
}

.tl{
    font-size: 1.5rem;
    font-family: var(--text-font);
    color: var(--text-color);
}

.txl{
    font-size: 2rem;
    font-family: var(--text-font);
    color: var(--text-color);
}

.txxl{
    font-size: 2.5rem;
    font-family: var(--text-font);
    color: var(--text-color);
}

.tpop{
    color: var(--pop);
}

.tcenter{
    text-align: center;
}

.tbold{
    font-family: var(--bold-font);
}

.button{
    text-decoration: none;
    background-color: var(--bg2);
    color: var(--text-color);
    margin: auto;
    display: table;
    padding: 6px 10px;
    border-radius: 10000px;
    background-color: var(--bg2);
}