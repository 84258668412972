.pro-text-container{
    width: 50%;
    min-width: 300px;
    margin: auto;
    background-color: var(--bg2);
    border-radius: .5vh;
    padding: 1% 2%;
}

.pro-text{
    color: var(--text-color);
    font-size: medium;
    font-family: 'Courier New', Courier, monospace;
}

.pro-text-header{
    color: var(--text-color);
    margin: 2vh 0vh 4vh 0vh;
    text-align: center;
    word-wrap: normal;
    font-size: large;
    font-family: 'Courier New', Courier, monospace;
}

.pro-link{
    text-decoration: none;
    margin: 0;
}

.pro-button-opened{
    display:table;
    margin: auto;
    padding: 1vh 2.5vh;
    margin-bottom: 2vh;
    border-radius: .5vh;
    background-color: var(--bg2);
}

.pro-button-closed{
    display:table;
    margin: auto;
    padding: 1vh 2.5vh;
    margin-bottom: 2vh;
    border-radius: 10000px;
    background-color: var(--bg2);
}

.pro-button-header{
    color: var(--text-color);
    text-align: center;
    word-wrap: normal;
    font-size: large;
    font-family: 'Courier New', Courier, monospace;
}