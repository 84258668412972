.main{
    width: 100%;
    height: 100%;
}

.message-container{
    width: 100%;
    margin: auto;
    margin-top: 10vh;
}

.big-message{
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20vh;
    color: var(--text-color);
}

.small-message{
    text-align: center;
    padding: 0px 10px;
    color: var(--text-color);
}

.return-link{
    text-decoration: none;
    margin: 0;
}