.about-text-container{
    width: 50%;
    min-width: 300px;
    margin: auto;
    background-color: var(--bg2);
    border-radius: .5vh;
    padding: 1% 2%;
}

.about-text{
    color: var(--text-color);
    font-size: medium;
    font-family: 'Courier New', Courier, monospace;
}

.about-text-header{
    color: var(--text-color);
    margin: 2vh 0vh 4vh 0vh;
    text-align: center;
    word-wrap: normal;
    font-size: large;
    font-family: 'Courier New', Courier, monospace;
}